import { useEffect, useRef, useState } from "react";
import "./App.scss";
import data from "./data.json";
import Row from "./components/Row";
import { useCookies } from "react-cookie";
import CookieConsent from "./components/CookieConsent";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const galleryNailsItems = importAll(
  require.context("./assets/gallery/nails", false, /\.(png)$/)
);

const galleryHairItems = importAll(
  require.context("./assets/gallery/hair", false, /\.(png)$/)
);

const galleryNailsArray = Object.entries(galleryNailsItems);
const galleryHairArray = Object.entries(galleryHairItems);

const contentItems = importAll(
  require.context("./assets", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  const [cookies] = useCookies(["cookieConsent"]);

  const [toggleService, setToggleService] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const { services } = data;

  const getServices = (type) => {
    return type.map((service, idx) => (
      <Row service={service} key={`${service}-${idx}`} />
    ));
  };

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showLine, setShowLine] = useState([]);

  useEffect(() => {
    const logoTimer = setTimeout(() => setShowLogo(true), 200);
    const cookieConsentTimer = setTimeout(() => {
      setShowCookieConsent(true);
    }, 900);
    const btnTimer = setTimeout(() => {
      setShowBtn(true);
    }, 1200);
    const linesInterval = setTimeout(() => {
      let i = 0;

      setInterval(() => {
        setShowLine((prev) => [...prev, i]);
        i += 1;
        if (i >= 4) {
          clearInterval(linesInterval);
        }
      }, 800);
    }, 1400);

    return () => {
      clearTimeout(logoTimer);
      clearTimeout(btnTimer);
      clearTimeout(cookieConsentTimer);
    };
  }, []);

  const content = useRef(null);
  const [rect, setRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setRect(content.current.getBoundingClientRect());
  }, [toggleService]);

  const servicesRef = useRef(null);

  const galleryRef = useRef(null);
  const [galleryRect, setGalleryRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setGalleryRect(galleryRef.current.getBoundingClientRect());
  }, []);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const galleryItems = (array) => {
    return array.map((item, idx) => {
      return (
        <div
          className="app_gallery__items_item fade-in-fwd"
          style={{
            "min-width": `calc(${
              (galleryRect.width - 80 * 2) /
                (screenSize.width >= 900
                  ? 5
                  : screenSize.width >= 520
                  ? 3
                  : screenSize.width >= 420
                  ? 2
                  : 1) -
              0.7 * 16
            }px  )`,
          }}
        >
          <img
            loading="lazy"
            src={item[1]}
            alt={`gallery-item-${idx}`}
            style={{
              "max-height": `calc(${
                (galleryRect.width - 80 * 2) /
                  (screenSize.width >= 900
                    ? 5
                    : screenSize.width >= 520
                    ? 3
                    : screenSize.width >= 420
                    ? 2
                    : 1) -
                0.7 * 16
              }px  )`,
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className="app">
      <header
        className="app_header"
        ref={galleryRef}
        style={{
          background: ` linear-gradient(to right, rgba(65, 34, 52,.5), rgba(65, 34, 52,.5)), center / cover no-repeat url(${contentItems["main-bg.png"]})`,
        }}
      >
        <div className="app_header__content">
          <div className="app_header__content_top">
            <div className="app_header__content_top_img-cont">
              <img
                loading="lazy"
                className={showLogo ? "show" : ""}
                style={
                  showLogo
                    ? { scale: "1", opacity: "1" }
                    : { scale: "0.7", opacity: "0" }
                }
                src={contentItems["logo.svg"]}
                alt="logo"
              />
            </div>
            <div className="app_header__content_top_btns">
              <div
                className="btn"
                style={showBtn ? { opacity: "1" } : { opacity: "0" }}
              >
                <button
                  style={
                    showBtn
                      ? { scale: "1", rotate: "0.0001deg" }
                      : { scale: "0.85" }
                  }
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() =>
                    window.open(
                      "https://www.treatwell.lt/salonas/kirpeja-ksenija/",
                      "_blank"
                    )
                  }
                >
                  <img
                    loading="lazy"
                    className={` ${showBtn ? "rotate-vert-center" : ""}`}
                    style={isHovered ? { scale: "1" } : { scale: "0.85" }}
                    src={contentItems["reservation.png"]}
                    alt="chair-icon"
                  />
                </button>
                <a
                  href="https://www.treatwell.lt/salonas/kirpeja-ksenija/"
                  target="_blank"
                  rel="noreferrer"
                >
                  rezervacija
                </a>
              </div>
              <div
                className="btn"
                style={showBtn ? { opacity: "1" } : { opacity: "0" }}
              >
                <button
                  style={
                    showBtn
                      ? { scale: "1", rotate: "0.0001deg" }
                      : { scale: "0.85" }
                  }
                  onClick={() =>
                    window.scrollTo({
                      top: servicesRef.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                >
                  <img
                    loading="lazy"
                    className={`${showBtn ? "rotate-vert-center" : ""}`}
                    style={isHovered2 ? { scale: "1" } : { scale: "0.75" }}
                    src={contentItems["services.png"]}
                    alt="services-icon"
                  />
                </button>
                <a
                  href=""
                  onClick={() =>
                    window.scrollTo({
                      top: servicesRef.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                >
                  paslaugos
                </a>
              </div>
            </div>
          </div>
          <div className="app_header__content_bottom">
            <div className="app_header__content_bottom_socials">
              <div
                className="social"
                style={
                  showLine.length >= 4 ? { opacity: "1" } : { opacity: "0" }
                }
              >
                <a
                  href="https://www.google.com/maps/place/A.+Go%C5%A1tauto+g.+3,+01105+Vilnius/@54.690889,25.276273,16z/data=!4m6!3m5!1s0x46dd94059d27e22f:0xc0d8ec15f538912!8m2!3d54.6908892!4d25.2762732!16s%2Fg%2F11c2f30m63?hl=en&entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    src={contentItems["geo.png"]}
                    alt="geolocation"
                  />
                </a>
              </div>
            </div>
            <div className="app_header__content_bottom_contact">
              <p
                style={
                  showLine.length >= 1 ? { opacity: "1" } : { opacity: "0" }
                }
              >
                Registracija telefonu:
              </p>
              <a
                href="tel:860790278"
                style={
                  showLine.length >= 2 ? { opacity: "1" } : { opacity: "0" }
                }
              >
                860790278
              </a>
              <p
                style={
                  showLine.length >= 3 ? { opacity: "1" } : { opacity: "0" }
                }
              >
                Goštauto g. 3, Vilnius
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="app_main" ref={servicesRef}>
        <div className="app_main_sales">
          <h2>Karšti pasiūlymai</h2>
          <div className="app_main_sales__content">
            <p>
              Akcija moteriškas modelinis kirpimas + galvos masažas
              <span> tik 20€</span>
            </p>
            <div className="divider"></div>
            <p>
              Akcija manikiūras su ilgalaikiu lakavimu ir pedikiūras su
              ilgalaikiu lakavimu <span>tik 40€</span>
            </p>
          </div>
        </div>
        <div className="app_main_nav">
          <button
            onClick={() => setToggleService(false)}
            className={!toggleService ? "active" : ""}
          >
            Plaukai
          </button>
          <button
            onClick={() => setToggleService(true)}
            className={toggleService ? "active" : ""}
          >
            Manikiūras
          </button>
        </div>
        <div
          className="app_main__table"
          style={{
            transition: "1s ease",
            height: `calc(${rect.height}px + 6em)`,
            overflow: "hidden",
          }}
        >
          <table className={toggleService ? "fade-in-fwd" : ""} ref={content}>
            <tbody>
              {toggleService
                ? getServices(services?.nails)
                : getServices(services?.hair)}
            </tbody>
          </table>
        </div>
        <div className="app_main_contact">
          <h2>Registracija telefonu</h2>
          <button>
            <a href="tel:860790278">860790278</a>
          </button>
        </div>
        <div className="app_gallery">
          <div className="app_gallery__items">
            {toggleService
              ? galleryItems(galleryNailsArray)
              : galleryItems(galleryHairArray)}
          </div>
        </div>

        <div className="app_main_about">
          <h2>Apie mus</h2>
          <p>
            Septintas dangus – tai ne tik itin profesionalios paslaugos, bet ir
            jaukus salonas, kuriame visada laukiami svečiai. Jeigu norite pabūti
            praktiškai namuose, išgerti puodelį kavos, jaustis geriau ir
            gražesni, tokiu atveju mes Jūsų laukiame. Mes priversime Jus
            spindėti! Su meile kuriame grožį. Viskas Jūsų tobulumui. Dėmesingi
            meistrai atsižvelgs ir įkūnys visus jūsų pageidavimus. <br />
            <br />
            Tegul jūsų kelias į grožį ir tobulumą būs lengvas. Su nuoširdžia
            meile dovanojame gerą nuotaiką ir optimizmą!
          </p>
        </div>
      </main>
      {!cookies.cookieConsent && showCookieConsent && <CookieConsent />}
    </div>
  );
}

export default App;
