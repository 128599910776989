import React from "react";

const Row = ({ service }) => {
  const { title, price, onSale, salePrice } = service;
  return (
    <tr className={`fade-in-fwd ${onSale ? "saleItem" : ""}`}>
      <td>
        <p>
          {onSale ? "akcija " : ""}
          {title}
        </p>
      </td>
      <td>
        {onSale ? (
          <p>
            <span className="oldPrice">{price} </span>
            <br />
            tir {salePrice}
          </p>
        ) : (
          <p>{price}</p>
        )}
      </td>
      <td>
        <button
          onClick={() =>
            window.open(
              `https://book.treatwell.lt/salonas/100042228/meniu/`,
              "_blank"
            )
          }
        >
          registracija
        </button>
      </td>
    </tr>
  );
};

export default Row;
