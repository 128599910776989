import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="cookie-consent fade-in-fwd-2">
      <p>
        Mes naudojame slapukus, kad suteiktume jums geriausią patirtį mūsų
        svetainėje. Jei ir toliau naudositės svetaine, manysime, kad esate ja
        patenkinti.{" "}
        <Link target="_blank" to={"/privacy-policy"}>
          Learn more
        </Link>
      </p>
      <button onClick={giveCookieConsent}>OK</button>
    </div>
  );
};

export default CookieConsent;
