import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div class="nf_section">
      <h1 class="error">404</h1>
      <div class="nf_page">
        Oi!!! Puslapiai, kurių ieškote, nėra finansuojami
      </div>
      <Link to="/">
        <button className="nf_back_home" variant="light">
          Atgal į pagrindinį puslapį
        </button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
