import React from "react";
import logo from "../assets/logo.svg";

const PrivacyPolicyPage = () => {
  return (
    <div className="pp-wrapper">
      <div className="pp-wrapper__logo-cont">
        <img src={logo} alt="logo" />
      </div>
      <h1>Privatumo Politika</h1>
      <div className="pp-wrapper__content">
        <p>
          Nulla vel quam neque. Cras porta sagittis leo vel iaculis. Proin
          luctus lobortis tellus, at facilisis risus ultrices eu. In sodales
          mollis nibh, at tempor lectus hendrerit et. Vestibulum finibus tempus
          nisi, sed feugiat neque lobortis ac. Duis aliquam a est in gravida.
          Aliquam erat volutpat. Sed hendrerit sapien a tortor semper, et tempor
          dolor porta.
        </p>
        <p>
          Nullam et orci dictum, ultricies nunc nec, commodo justo. Ut gravida
          ipsum quis gravida tristique. In consequat placerat lobortis. Nam
          bibendum congue felis, ac lacinia sem lacinia a. Proin sit amet tellus
          dui. Mauris ultricies sollicitudin risus, quis rhoncus nunc mattis eu.
          Ut sit amet felis a ex auctor dictum sed id sem. Suspendisse
          sollicitudin, lacus id placerat volutpat, lacus elit imperdiet mauris,
          et porta risus risus sed augue. Praesent aliquet ex ipsum, non
          accumsan lectus porttitor vitae. Mauris quam mauris, auctor porttitor
          ullamcorper et, rhoncus quis dolor. Suspendisse sodales faucibus
          justo, eget gravida nisl varius id.
        </p>
        <p>
          Aenean nec nisi vel tellus accumsan molestie. Vestibulum at elit nibh.
          Maecenas vel nisi ac metus accumsan convallis. Nulla eu ipsum neque.
          Etiam nec maximus odio. Nullam sed mi nulla. Etiam urna est, facilisis
          ac sollicitudin et, pellentesque vitae sapien. Praesent consectetur
          urna arcu, id posuere urna consequat ut. Nunc vitae iaculis quam.
          Morbi at fringilla justo. In mauris libero, porttitor quis dolor nec,
          viverra dictum massa. Suspendisse auctor tristique porttitor.
        </p>
        <p>
          Proin euismod ligula ut lectus luctus, sit amet cursus mi imperdiet.
          Pellentesque scelerisque mauris id est vestibulum, et tempus dolor
          laoreet. Sed condimentum ligula nibh, sit amet aliquam elit posuere
          ac. Pellentesque eu nisi metus. Nunc blandit ex facilisis pellentesque
          volutpat. Mauris et accumsan felis. Vestibulum aliquam, mauris nec
          vestibulum pharetra, nibh nibh dignissim nibh, ultricies euismod enim
          neque ut lectus. Nunc quis tellus ac enim interdum egestas eget in
          erat. Vestibulum ac semper nisl. Aliquam maximus molestie mattis. Sed
          sed tincidunt diam. Nulla mattis consequat magna id hendrerit. Proin
          vulputate, odio a aliquet faucibus, nulla leo maximus turpis, quis
          bibendum purus arcu sit amet ligula.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
